/* Keyframe for the heartbeat animation */
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.socialnediaabourt {
  position: fixed !important;
  top: 27%;
  z-index: 999999999999;
  left: 0;
}

.socialnediaabourt .sideheading span:after {
  border-bottom: 0;
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background: #5191a1;
  top: 10px;
  left: 175px;
}

.socialnediaabourt .sideheading span:before {
  border-bottom: 0;
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  background: #5191a1;
  top: 10px;
  left: -60px;
}

.socialnediaabourt .sideheading span {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  float: left;
  margin-left: -53px;
  margin-top: 144px;
  margin-bottom: 137px;
  font-weight: 700;
  color: #5191a1;
  font-size: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
  font-family: "Poppins";
}

.icon {
  height: 5px;
  width: 5px;
}

a:hover .icons {
  transition: 0.3s linear;
  transform: translateY(-3px) rotateY(180deg);
  font-size: 18px;
  text-shadow: -1px -1px 2px #fff, 1px 1px 2px #b8b7b7;
  color: rgb(255, 255, 255) !important;
  filter: drop-shadow(0px 0px 2px #9e9e9e);
}

.socialmediaside ul li {
  list-style: none;
  padding-left: 3px;
  transition: transform 0.3s ease;
}

.socialmediaside ul {
  padding-left: 18px;
}

.socialnediaabourt .sideheading span {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  float: left;
  margin-left: -53px;
  margin-top: 144px;
  margin-bottom: 137px;
  font-weight: 700;
  color: #5191a1;
  font-size: 14px;
  letter-spacing: 0;
  text-transform: uppercase;
}

.social-icon {
  transition: transform 0.3s ease, color 0.3s ease;
  animation: heartbeat 2s infinite 5s; /* Heartbeat animation triggers every 5 seconds */
}

.socialmediaside ul li:hover .social-icon {
  transform: rotate(180deg);
  color: white;
}

.icons {
  animation: heartbeat 2s infinite 5s;
}
@media (min-width: 320px) and (max-width: 700px) {
  .ai-content {
    max-width: 100%!important;
    padding: 0px!important;
}
  }