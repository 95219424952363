.hr1 {
    background: #001936;
    margin-top: 10px;
    width: 100px;
    height: 3px;
  }
  
  .hr1:hover {
    background: #001936;
    margin-top: 10px;
    width: 60px;
    height: 3px;
  }
  
  /* Change hr2 width when hr1 is hovered */
  .hr1:hover + .hr2 {
    width: 100px;
  }
  
  .hr2 {
    background: #001936;
    width: 60px;
    margin-top: 10px;
    height: 3px;
    transition: 0.3s linear;
  }