.animation-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.ai-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 101%;
    height: auto;
    z-index: -2;
}

.ai-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}
.animation-content {
    color: #fff;
    width: 44%;
    padding-left: 82px;
}
.animation-content h1{
    font-size: 39px;
}
.container.animionsects {
    text-align: center;
    padding-top: 20px;
}
.container.animionsects {
    text-align: center;
    padding-top: 20px;
}
.container.animionsects img {
    width: 100%;
}
.aminsecro1 h2 {
    font-weight: 600;
    padding-top: 7px;
}
.aminsecro1 h3 {
    font-size: 20px;
    font-weight: 400;
    padding-top: 18px;
}
.aminsecro1 p{
    font-size: 17px;
    padding-top: 6px;
    line-height: 29px;
    font-weight: 500;
    color: #00000075;
}
.row.aminsecro2 h4 {
    font-size: 16px;
    padding-top: 17px;
    font-weight: 300;
    line-height: 21px;
}
.row.aminsecro2 p {
    font-size: 14px;
    color: #00000063;
}
button.conow {
    margin-top: 24px;
    padding: 8px 18px;
    background: #006FDC;
    border: none;
    border-radius: 6px;
}
.bgforinmg{
    background-image: url('../../Assets/Images/animation/anibgimgftr.png');
    height: 60vh;
    background-size: cover;
}
@media (min-width: 320px) and (max-width: 767px) {
    .animation-content h1 {
        font-size: 19px;
        line-height: 32px;
    }
    .animation-content {
        padding-left: 11px;
        width: 100%;
        margin-top: 131px;
    }
    .animation-container {
        height: 84vh;
    }
    .ai-video {
        top: 21%;
    }
    .row.aminsecro2 {
        gap: 11px;
    }
    .bgforinmg {
        height: 17vh;
        margin-top: 19px;
    }
}