.consult-container {
    text-align: center; /* Center the button */
    margin-top: 20px;
  }
  
  .consult-button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 30px;
    padding: 10px 24px;
    font-size: 18px;
    /* font-weight: 600; */
    cursor: pointer;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    float: inline-start;
}
  
  .consult-button:hover {
    background-color: #ffffff; /* Darken background on hover */
    box-shadow: 0px 0px 24px 7px #70CCDA;
    transform: translateY(-2px); /* Slight movement on hover */
    color: black;
  }
  .consult-button:hover i#angle-icon {
    display: inline-block; /* Show the icon on hover */
    transition: 70ms ease;
}
  i#angle-icon {
    padding-left: 10px;
    display: none;
}