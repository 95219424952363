.iconxsx {
    position: relative;
    width: 100%;
    height: 500px;
  }

  .iconx-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 220px;
    /* background-color: #fff; */
    border-radius: 50%;
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); */
    animation: floatAround 5s ease-in-out infinite alternate;
  }

  .iconx {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  /* Positions */
  .google { top: 0px; left: -4%; transform: translateX(-50%); animation-delay: 0s; }
  .microsoft { top: 204px; left: -2%; animation-delay: 0.5s; }
  .bitbucket { top: 36px; left: 14%; animation-delay: 1s; }
  .digitalocean { top: 125px; left: 35%; animation-delay: 1.5s; }
  .ubuntu { top: -24px; left: 32%; animation-delay: 2s; }
  .nginx { top: 240px; left: 21%; animation-delay: 2.5s; }
  .github { top: -35px; left: 52%; animation-delay: 3s; }
  .facebook { top: 250px; left: 45%; animation-delay: 3.5s; }
  .wordpress { top: 105px; left: 57%; transform: translateX(-50%);animation-delay: 1s;}
  .wordpress1 { top: 252px; left: 72%; transform: translateX(-65%);animation-delay: 2s;}
  .wordpress2 { top: 30px; left: 79%; transform: translateX(-50%);animation-delay: 1.5s; }

  /* Floating animation in all directions */
  @keyframes floatAround {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(-15px, 10px);
    }
    50% {
      transform: translate(15px, -10px);
    }
    75% {
      transform: translate(-10px, -15px);
    }
    100% {
      transform: translate(10px, 15px);
    }
  }
  .google .iconx {
    background-image: url('../../Assets/Images/floatingicons/Group3471.png');
}

.microsoft .iconx {
    background-image: url('../../Assets/Images/floatingicons/Group3543.png');
}

.bitbucket .iconx {
    background-image: url('../../Assets/Images/floatingicons/Group3544.png');
}

.digitalocean .iconx {
    background-image: url('../../Assets/Images/floatingicons/Group3545.png');
}

.ubuntu .iconx {
    background-image: url('../../Assets/Images/floatingicons/Group3546.png');
}

.nginx .iconx {
    background-image: url('../../Assets/Images/floatingicons/Group3547.png');
}

.github .iconx {
    background-image: url('../../Assets/Images/floatingicons/Group3548.png');
}

.facebook .iconx {
    background-image: url('../../Assets/Images/floatingicons/Group3550.png');
}

.wordpress .iconx {
    background-image: url('../../Assets/Images/floatingicons/Group3553.png');
}
.wordpress1 .iconx {
  background-image: url('../../Assets/Images/floatingicons/Group3554.png');
}
.wordpress2 .iconx {
  background-image: url('../../Assets/Images/floatingicons/Group3555.png');
}