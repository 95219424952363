/* Popup overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.popup-overlay.hide {
  animation: fadeOut 0.3s forwards;
}

/* Popup content */
.popup-content {
  background: white;
  padding: 20px 0px;
  border-radius: 8px;
  width: 365px;
  position: relative;
  transform: scale(0.95);
  animation: scaleIn 0.3s forwards;
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); */
  height: 300px;
  margin: 12px 1px;
}

.popup-content.hide {
  animation: scaleOut 0.3s forwards;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  cursor: pointer;
}

form h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

form label {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 5px;
  display: block;
  font-size: 12px;
  text-align: justify;
  font-weight: 700;
}
form input,
form textarea :focus {
  outline: none;
}

form input,
form textarea {
  width: 100%;
  padding: 4px;
  margin-bottom: 10px;
  /* border-bottom: 1px solid #ddd; */
  /* border-radius: 5px; */
  font-size: 0.95rem;
  background: #f9f9f9;
  /* border: none; */
  border: none;
  border-bottom: 1px solid #000000a8;
  background: transparent;
}
div#first-sec-form {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
form textarea {
  resize: none;
  height: 80px;
}

form .submit-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

form .submit-button:hover {
  background-color: #0056b3;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.95);
  }
}

#popup-div {
  position: absolute;
  top: 108px;
  background: #fefefe;
  height: 66vh;
  width: 60vw;
  border-radius: 9px;
  box-shadow: 0px 0px 5px 2px #70ccda;
  border: 1px solid #888;
}
#popup-div img {
  width: 85%;
}
img#ali-img {
  height: 100px;
  width: 100%;
  margin-top: -10px;
  object-fit: contain;
}
.submit-buttons {
  font-size: 14px !important;
  padding: 10px 22px !important;
  border: 2px solid #434ca0 !important;
  background: #454c90 !important;
  border-radius: 30px !important;
  color: #fff !important;
  font-weight: 700 !important;
  width: 70% !important;
}
/* .submit-button:hover{
  border: 2px solid #434ca0;
  background-color: white;
  color: #454c90;
} */
