/* Combined style.css */

/* Body and background styles */
img#viewallbutton {
  position: fixed;
  right: 1px;
  width: 7%;
  top: 88px;
  transition: transform 0.3s ease, filter 0.3s ease;

}
img#viewallbutton:hover {
  transform: scale(1.2);
  /* filter: blur(1px); */
}
.bodys-1 {
  margin: 0;
  background-color: #181717;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

#app {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hero-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease-in-out;
  z-index: -1;
}

.hero-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vw;
  background-color: #000000b8; /* Adjust the opacity as needed */
}

.hero-title {
  position: absolute;
  left: 0.3rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 2.2rem;
  color: rgba(255, 255, 255, 0.2);
  mix-blend-mode: overlay;
  -webkit-animation: blurFadeIn 0.6s both;
  animation: blurFadeIn 0.6s both;
}

@media (max-width: 992px) {
  .hero-title {
    top: 2rem;
    bottom: auto;
  }
}

.round {
  position: relative;
  width: 17rem;
  height: 17rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translateZ(0);
  perspective: 1000px;
  margin: 27%;
}
.round:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 17rem;
  height: 17rem;
  margin: auto;
  border-radius: 50%;
  border-top: 5px solid #fff;
  -webkit-animation: rotate 5s 0.2s both linear infinite;
  animation: rotate 5s 0.2s both linear infinite;
}
.row.appbtn {
  display: flex;
  flex-direction: row;
  gap: 9px;
  width: 100%;
}
.row.appbtn .col-md-6 {
  width: 25%;
  padding: 0px;
  /* text-align: center; */
}
.row.appbtn img {
  width: 95%;
}
.row.appbtn img:hover {
  box-shadow: 0px 0px 16px 7px #70ccda;
  border-radius: 20px;
 transform: scale(1.1);}


.item {
  position: absolute;
  width: 7rem;
  height: 7rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.item .inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item.active {
  transform: scale(1.2);
  filter: drop-shadow(0px 1px 0px #70ccda);
  border: 1px solid #70ccda;
  border-radius: 50%;
}

.hero-info {
  width: 81%;
  background: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  background-color: #000000a3;
  padding-left: 25px;
  padding-top: 70px;
  padding-bottom: 70px;
  margin: 120px 0px;
}

.hero-info .row {
  margin-bottom: 0.5rem;
  -webkit-animation: fadeInFromTop 0.5s both;
  animation: fadeInFromTop 0.5s both;
}

.hero-info .row:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.hero-info .row:nth-of-type(2) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.hero-info .row:nth-of-type(3) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.hero-info .row:nth-of-type(4) {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.hero-info .title {
  font-size: 1.1em;
  font-weight: bold;
  text-transform: uppercase;
  color: #09c3e2;
}

.hero-info .title:before {
  content: "# ";
}

.hero-info .content {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  padding: 0px;
}

.hero-info .desc {
  font-size: 1rem;
  color: #666;
}

.hero-info .content.desc {
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  padding: 24px 0px;
  padding-top: 7px;
  width: 95%;
  color: white;
}

/* Animation and transition styles */
@-webkit-keyframes roundItemFadeIn {
  from {
    opacity: 0;
    transform: none;
  }
}

@keyframes roundItemFadeIn {
  from {
    opacity: 0;
    transform: none;
  }
}

@-webkit-keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-0.2rem);
  }
}

@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-0.2rem);
  }
}

@-webkit-keyframes fadeInFromRight {
  from {
    opacity: 0;
    transform: translateX(0.5rem);
  }
}

@keyframes fadeInFromRight {
  from {
    opacity: 0;
    transform: translateX(0.5rem);
  }
}

@-webkit-keyframes blurFadeIn {
  from {
    filter: blur(10px);
    letter-spacing: -0.4rem;
  }
}

@keyframes blurFadeIn {
  from {
    filter: blur(10px);
    letter-spacing: -0.4rem;
  }
}

@-webkit-keyframes scaleUp {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
}

@keyframes scaleUp {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
}

@-webkit-keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

/* .viewbtn section */
.viewbtn {
  position: fixed;
  top: 162px;
  right: -29px;
  width: 10%;
  z-index: 111;
}

.viewbtn img {
  width: 79%;
}

.viewbtn img:hover {
  filter: drop-shadow(1px 1px 2px #70ccda);
}

.viewbtn span {
  padding-top: 9px;
}

.viewbtn img.hovimg1 {
  display: none;
}

.viewbtn:hover img.hovimg1 {
  display: block;
}

.viewbtn:hover img.hovimg2 {
  display: none;
}

/* Responsive styles */
@media (max-width: 992px) {
  #app {
    grid-template-columns: 100%;
    row-gap: 1rem;
  }

  .hero-info {
    width: 100%;
  }

  .hero-title {
    top: 2rem;
    bottom: auto;
  }

  .viewbtn {
    top: 110px;
  }
}
div#portolio {
  /* position: absolute; */
  top: 17%;
  /* height: auto; */
  left: 15%;
  margin: 0px;
  padding: 0px;
  /* position: fixed; */
}
#portolio .col-md-6{
  /* height: 50vh; */
  overflow: hidden;
}
.round .item {
  animation: roundItemFadeIn 1s ease-in-out;
}
.round .item.active {
  animation: scaleUp 0.6s ease-out;
}

@media (min-width: 320px) and (max-width: 767px) {
.hero-info {
  margin: 30px 0px;
  background: transparent;
  box-shadow: none;
}
.portrow{
  display: flex;
  flex-direction: column-reverse;
}
.opcity{
  opacity: 0;
}
img#viewallbutton {
  z-index: 111;
    width: 22%;
}
}