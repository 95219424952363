.ai-container {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Full viewport height */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    /* Remove any default container margins */
    padding: 0;
    /* Ensure no padding creates spacing */
}

/* Background video styling */
.ai-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 101%;
    height: auto;
    z-index: -2;
    /* Place behind the overlay and content */
}

/* Black overlay layer */
.ai-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    z-index: -1;
    /* Below content but above video */
}

/* Content styling - centered in the container */
.ai-content {
    position: relative;
    text-align: center;
    color: white;
    z-index: 1;
    max-width: 67%;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
}

/* Headings and paragraph styling */
.ai-content h1 {
    font-size: 39px;
    margin-bottom: 20px;
    line-height: 56px;
}

.ai-content p {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 37px;
}

.cntrbtn {
    display: flex;
    justify-content: center;
}

/* Floating icons section */
.floating-icons-section {
    padding: 20px;
    background-color: #f9f9f9;
}

.flotingwork {
    position: relative;
    text-align: center;
    z-index: 1;
    max-width: 66%;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
}

.flotingwork h2 {
    font-size: 39px;
    line-height: 55px;
    color: #fff;
    background: #061722;
    box-shadow: 0px 0px 63px 64px #061722;
    margin-top: -31px;
}

.flotingaisecon {
    background: #061722;
    box-shadow: 0px -96px 110px 4px #061721;
}

.flotingwork {
    background-image: url(../../Assets/Images/Group3440.png);
    background-size: cover;
}

.aisecon3 {
    padding: 0px 42px;
    margin-top: -78px;
}

/* cards css .. . .. */

.mycdr .cards-container {
    display: grid!important;
    grid-template-columns: repeat(3, 1fr)!important;
    gap: 20px!important;
    width: 100%!important;
}

.cards {
    background-color: #18222d;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    border: 2px solid #f8f9fa29;
}

/* Adding the exact bottom-right curved design */
.cards::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: #10171e;
    clip-path: polygon(0 100%, 100% 100%, 100% 0);
}

.cards h3 {
    margin-top: 0;
    font-size: 18px;
    color: #fff;
    line-height: 26px;
}

.cards p {
    font-size: 13px;
    margin: 15px 0 0;
    color: #fff;
    line-height: 23px;
}

.cards .icons {
    position: absolute;
    top: 14px;
    right: 12px;
    color: #1cb0f6;
}

.cards .icons img {
    width: 50px;
}

@media screen and (max-width: 768px) {
    .cards-container {
        grid-template-columns: 1fr;
    }
}

.aisecon3s h2 {
    color: #fff;
    font-size: 27px;
    line-height: 39px;
}

.aisecon3s p {
    color: #fff;
    font-size: 16px;
    padding-right: 41%;
    font-weight: 300;
    line-height: 30px;
}

.newrowai {
    background-image: url('../../Assets/Images/PORTFOLIO.png');
    height: 28vh;
    background-size: cover;
}
.newrowais {
    background: #061722;
    padding-top: 42px;
    padding-bottom: 42px;
}
.appsection{
padding: 0px 42px;
}
.newaisecon3s p {
    padding-right: 27%;
    padding-top: 9px;
}
.newaisecon3s {
    padding-top: 59px;
}
.botimgs {
    text-align: center;
}
.botimgsx {
    text-align: center;
}
.botimgs img {
    width: 85%!important;
    margin-left: 0px!important;
}
.botimgsx img {
    width: 61% !important;
    margin-left: 0px!important;
}
.appsection h3 {
    color: #fff;
    font-size: 33px;
}
.appsection p{
    color: #ffffff94;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
}
.appsection img {
    width: 75%;
    margin-left: 90px;
}

.lineaferbfr , .lineaferbfrx , .lineaferbfrv{
    position: relative;
}

  .lineaferbfr::before {
    content: "";
    position: absolute;
    width: 10%;
    height: 2px;
    background-color: #fff;
    top: 85.2%;
    transform: translateY(-50%);
    left: -109px;
  }
  
  .lineaferbfr::after {
    content: "";
    position: absolute;
    width: 69%;
    height: 2px;
    background-color: #fff;
    top: 85.2%;
    transform: translateY(-50%);
    right: 27px;
  }
  .lineaferbfrx::before {
    content: "";
    position: absolute;
    width: 10%;
    height: 2px;
    background-color: #fff;
    top: 85.2%;
    transform: translateY(-50%);
    left: -109px;
  }
  
  .lineaferbfrx::after {
    content: "";
    position: absolute;
    width: 50.6%;
    height: 2px;
    background-color: #fff;
    top: 85.2%;
    transform: translateY(-50%);
    right: 27px;
  }
  .lineaferbfrv::before {
    content: "";
    position: absolute;
    width: 10%;
    height: 2px;
    background-color: #fff;
    top: 85.2%;
    transform: translateY(-50%);
    left: -109px;
  }
  
  .lineaferbfrv::after {
    content: "";
    position: absolute;
    width: 74%;
    height: 2px;
    background-color: #fff;
    top: 85.2%;
    transform: translateY(-50%);
    right: 27px;
  }
  
  
/* accordion css... .. */
/* Main Container */
.ai-page-container {
    display: flex;
    flex-direction: row;
    color: white;
    padding: 0px;
    border-radius: 12px;
    box-shadow: 0px -6px 5px 3px #0c768a61;
    gap: 30px;
    margin-top: 100px;
}

/* Left Section */
.left-section {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}
.icon-section img{
width: 14%;
    margin-left: 0px;
}

.text-section h2 {
    font-size: 28px;
    margin-bottom: 15px;
    line-height: 1.4;
    color: #e2e8f0;
}

.text-section p {
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
}

.text-section blockquote {
    font-size: 14px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.7);
    border-left: 4px solid #4f46e5;
    padding-left: 10px;
    margin-top: 20px;
}

/* Right Section (Accordion) */
.right-section {
    flex: 1 1;
    display: flex
;
    flex-direction: column;
    gap: 15px;
    background-color: #1f3943;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

/* Accordion Styles */
.accordion-item {
    padding: 15px 20px;
    border-radius: 6px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    cursor: pointer;
    transform-origin: center;
    transform: scale(0.98);
    opacity: 0.9;
}

.accordion-item.active {
    transform: scale(1);
    opacity: 1;
    background-color: #1e3c62;
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #f1f5f9;
}

.accordion-header h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    flex: 1;
}

.accordion-icon {
    font-size: 20px;
    font-weight: bold;
    color: #cbd5e1;
    transition: transform 0.3s ease;
}

.accordion-item.active .accordion-icon {
    transform: rotate(90deg);
}

.accordion-content {
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.9);
    max-height: 150px;
    overflow: hidden;
    transition: all 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
    .ai-page-container {
        flex-direction: column;
    }

    .right-section {
        margin-top: 20px;
    }
}
/* aifootercsss ... . . */
.bgcsd {
    background: #061722;
    margin-top: -90px;
    color: #fff;
}
.bgcsd .row.upper-footer-main {
    display: none;
}
.bgcsd .social-icons {
    margin: 0px!important;
}
.bgcsd .contctsecf h2 {
    color: #fff;
}
.bgcsd .underline {
    background: #f8f9fa;
}
.bgcsd .linesv {
    background: #f8f9fa;
}
.bgcsd .secms1 h1:hover {
    color: #ffff !important;
}
.bgcsd .blockrow1 li a:hover {
    color: #fff !important;
}
.bgcsd .blockrow1 h1 {
    font-size: 20px;
    font-weight: 500;
    color: #67b3ce;
}
/* mobileressponshive css . .. . */
@media (min-width: 320px) and (max-width: 700px) {
.socialnediaabourt {
    display: none!important;
}
.flotingwork {
    max-width: 100%!important;
    padding: 0px!important;
  }
  .flotingwork h2 {
    font-size: 17px!important;
    line-height: 30px!important;
  }
  
  .floatingbg .container-fluid {
    padding: 0;
  }
  .floatingbg .col-md-12 {
    padding: 0;
  }
  .iconxsx {
    overflow: hidden;
  }
  .google {
    left: -12% !important;
  }
  .microsoft {
    top: -47px!important;
    left: 45%!important;
  }
  .bitbucket {
    top: -72px!important;
    left: -25%!important;
  }
  .digitalocean {
    top: 79px!important;
    left: 12%!important;
  }
  .ubuntu {
    top: 196px!important;
    left: 37%!important;
  }
  .nginx {
    top: 10px!important;
    left: 25%!important;
  }
  .github {
    top: 146px!important;
    left: -2%!important;
  }
  .facebook {
    top: 98px!important;
    left: 55%!important;
  }
  .wordpress {
    top: 233px!important;
    left: -22%!important;
  }
  .wordpress1 {
    top: -67px!important;
    left: 7%!important;
  }
  .wordpress2 {
    top: 86px!important;
    left: -15%!important;
  }
  .aisecon3 {
    padding: 0px 0px!important;
  }
  .aisecon3s p {
    padding-right: 0%!important;
  }
  .newmycdr .mycdr .cards-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .appsection {
    padding: 0px 7px;
}
.botimgs img {
    width: 100% !important;
}
.ai-video {
    top: 15%;
}
.ai-container{
    padding-top: 80px !important;
}
.ai-content h1 {
    font-size: 19px;
    margin-bottom: 0px;
    line-height: 32px;
}
.ai-content p {
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 24px;
}
.consult-container {
    margin-top: 8px;
}
}