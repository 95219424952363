.upper-footer-main {
  border-radius: 9px 9px 9px 9px;
  overflow: hidden;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 43px 0px;
  margin-top: 50px;
}
/* .upper-footer-main img {
  display: inline-block;
  margin-bottom: 30px;
  width: 150px;
  height: 70px;
  object-fit: contain;
}
img.none {
  display: none;
} */

.upper-footer-main {
  border-radius: 9px 9px 9px 9px;
  overflow: hidden;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 43px 0px;
  margin-top: 50px;
}

.upper-footer-main .mychngeimg:hover img {
  display: none;
}

.upper-footer-main .mychngeimg:hover img.none {
  display: inline-block;
}

.upper-footer-main img {
  display: inline-block;
  margin-bottom: 30px;
  width: 150px;
  height: 70px;
  object-fit: contain;
}

img.none {
  display: none;
}

.left-con-sec-1 {
  display: flex;
  gap: 18px;
}
.left-con h3 {
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  padding-top: 20px;
  opacity: 0.7;
}
.left-con img {
  width: 35px;
  height: 35px;
  margin-top: 66px;
}
img#telephone {
  width: 50%;
  margin-left: 88px;
}
.et_pb_text_1_tb_footer {
  padding-top: 96px !important;
}
.contctsecf h4 {
  color: #b6b6b6;
  font-size: 18px;
}
.contctsecf h2 {
  font-size: 23px;
  font-weight: 600;
  color: #001936;
  margin-top: 1px;
  line-height: 31px;
}

#footer-form {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

#footer-forms {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #001936;
  border-radius: 16px;
  color: #fff;
  padding: 32px;
  width: 500px;
  /* box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.3); */
  text-align: center;
}
#footer-forms:hover {
  box-shadow: 0px 0px 24px 7px #70ccda;
}

#footer-forms .form-row {
  display: flex;
  gap: 20px;
}

#footer-forms input[type="text"],
#footer-forms input[type="email"],
#footer-forms input[type="tel"],
#footer-forms textarea {
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  padding: 10px;
  width: 100%;
}
#footer-forms input[type="text"]:focus,
#footer-forms input[type="email"]:focus,
#footer-forms input[type="tel"]:focus,
#footer-forms textarea:focus,
.captcha-section input:focus {
  outline: none;
  border-bottom: 1px solid #70ccda; /* Light blue border on focus */
}
#footer-forms input::placeholder,
#footer-forms textarea::placeholder {
  color: #fff;
}

#footer-forms textarea {
  resize: none;
  height: 80px;
}

.captcha-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.captcha-section label {
  font-size: 16px;
  color: #fff;
}

.captcha-section input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  padding: 10px;
  width: 50%;
}

button[type="submit"] {
  background: #fff;
  color: #001936;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100px;
  border-radius: 65px;
}

button[type="submit"]:hover {
  background-color: transparent;
  border: 1px solid #fff;
  color: white;
}

img#ibs-logo {
  width: 25%;
  margin: 50px 0px;
}
input#answers {
  width: 44% !important;
}
.error {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 5px;
}
.captcha-section img {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  vertical-align: middle;
}


