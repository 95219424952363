.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  background-color: black; /* Black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999; /* Ensure it stays above other content */
}

.header-loader {
  position: relative;
  width: 15%; /* Adjust the loader size */
}

.header-loader::before,
.header-loader::after {
  content: '';
  position: absolute;
  border: 2px solid rgba(255, 255, 255, 0.5); /* Light glowing border */
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
  z-index: -1;
  background-color: red; /* Red background for the pseudo-elements */
}

.header-loader::before {
  width: 120%;
  height: 120%;
  top: -10%;
  left: -10%;
}

.header-loader::after {
  width: 140%;
  height: 140%;
  top: -20%;
  left: -20%;
}

