.underline {
  height: 2px;
  background: #000;
  width: 100%;
  margin-top: 21px;
}

.menu {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.secms1 h1 {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #9c9c9c8f;
  padding: 15px;
  cursor: pointer;
}
.secms1 h1 a {
  color: #9c9c9c8f !important;
}
.secms1 h1:hover {
  color: #001936 !important;
}
.secms1 h1 a:hover {
  color: #001936 !important;
}
.blockrow1 h1 {
  font-size: 20px;
  font-weight: 500;
  color: #00193c;
}
.blockrow1 ul {
  padding: 0px !important;
}
.blockrow1 li {
  margin-top: 2%;
  list-style: none;
  font-size: 15px;
  border-top: 0px;
  border-bottom: 0px;
}
.blockrow1 li a {
  font-size: 15px;
  font-weight: 500;
  color: #c7c7c7 !important;
  text-decoration: none;
}
.blockrow1 li a:hover {
  color: #001936 !important;
}
.blockrow1 {
  justify-content: center;
}
ul.ullistcs a:hover i.fa {
  /*     transform: rotate(358deg); */
}
ul.ullistcs a {
  border: 1px solid;
  padding: 22px;
  border-radius: 69px;
  margin: 19px;
}
ul.ullistcs i.fa {
  transform: scale(2.2);
  width: 2%;
}
.linesv {
  background: #00000070;
  height: 7vh;
  width: 1.5%;
  margin-left: 61px;
}
div#Services {
  padding-top: 2%;
  padding-bottom: 2%;
}
div#Industries {
  padding-top: 3%;
  padding-bottom: 3%;
}
div#Portfolio {
  padding-top: 3%;
  padding-bottom: 3%;
}
.blockrow1 {
  justify-content: left;
  display: flex;
  justify-content: center;
  gap: 150px;
  margin-top: 10px;
}
.blockrow1.neds {
  justify-content: normal;
  gap: 245px;
  padding-left: 12%;
  margin-top: 2%;
}
.leftside {
  margin-left: -126px;
}
.fa-facebook:hover {
  color: white;
}
.fa-linkedin:hover {
  color: white;
}
.fa-behance:hover {
  color: white;
}
.fa-dribbble:hover {
  color: white;
}
ul.ullistcs a:hover {
  background-color: #001936;
}

@media only screen and (max-width: 480px) {
  .blockrow1 li a {
    font-size: 13px !important;
  }
  .linesv {
    height: 5vh;
    margin-left: 6px;
    width: 0.1rem;
  }
  .blv {
    margin-top: 21% !important;
  }
  .secms1 h1 {
    font-size: 14px !important;
    padding: 0px;
  }
  .mobimg img {
    margin-left: 0%;
  }
  .blockrow1.neds {
    gap: 0px;
    padding-left: 0%;
    justify-content: space-evenly;
  }
  /* 	.menu {
    justify-content: center;
    gap: 6px;
} */
  .menu {
    justify-content: space-evenly;
    gap: 6px;
  }
  .blockrow1 {
    text-align: center;
  }
  .leftside {
    margin-left: 0px;
  }
  .blockrow1 h1 {
    font-size: 15px;
  }
  ul.ullistcs a {
    border: 1px solid;
    padding: 22px 29px 19px 23px;
    border-radius: 50%;
    margin: 7px;
  }
  .fotersec2 img {
    width: 70% !important;
  }
  div#Industries {
    padding: 3%;
  }
}
div#footer-row {
  padding-left: 130px;
  /* margin-top: 20px; */
}
.social-icons {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 35px;
  margin: 50px 0px;
}

.icon img {
  width: 60%;
}
.icon {
  width: 70px;
  border: 1px solid #001936;
  height: 70px;
  border-radius: 50px;
  padding: 11px 0px;
}
.icon:hover {
  background-color: #001936;
  cursor: pointer;
}
@media (min-width: 320px) and (max-width: 767px) {
.blockrow1 {
  display: flex;
  justify-content: center;
  gap: 0px;
  justify-content: space-between;
  padding: 0px !important;
}
.icon {
  border: none!important;
}
#footer-forms .form-row {
  display: block!important;
}
.captcha-section {
  display: grid!important;
  gap: 0!important;
}
.captcha-section input#answers {
  width: 100% !important;
}
.left-con img {
  margin-top: 2px!important;
}
img#ibs-logo {
  width: 75%!important;
}
.contctsecf h2 {
  font-size: 14px!important;
}
.left-con h3 {
  font-size: 15px!important;
  padding-top: 0px!important;
}
#gopaliya {
  float: unset!important;
}
.bg {
  width: 106%;
  margin-left: -8px;
}
}