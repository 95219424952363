.header-com {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 72px;
  /* background-color: #fff; */
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
  padding-left: 23px !important;
  transition: background-color 300ms ease, padding 900ms ease,
    box-shadow 300ms ease;
}
.header-com.scrolled .logoimgtex a {
  text-decoration: none;
}
nav.navbar {
  background: #fff;
  padding: 11px 26px;
  border-radius: 59px;
  padding-bottom: 0px;
  box-shadow: 0px 0px 24px 7px #70ccda;
  height: 10vh;
}
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  margin-right: 10px;
  width: 149px;
}

.logo span {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.navbar ul {
  display: flex;
  list-style: none;
  /* align-items: center; */
  margin-top: 0.5rem;
  justify-content: center;
}
.navbar li {
  margin: 0 12px;
  display: flex;
  gap: 35px;
}
#nav-list a {
  text-decoration: none;
  color: #0000008f;
  font-weight: 600;
  transition: color 0.3s ease;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  transition: color 0.3s ease, transform 0.3s ease;
  display: flex;
  gap: 10px;
}

#nav-list a:hover {
  color: #001936;
  /* text-decoration: underline !important; */
  /* transform: scale(1.05) rotate(-2deg) !important; */
  transition: transform 0.1s ease !important;
}

.connect-btn {
  padding: 10px 20px;
  border: 1px solid #42557082;
  border-radius: 20px;
  color: #425570;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.connect-btn:hover {
  color: #fff !important;
  background: linear-gradient(to bottom right, #536d83, #5bbdc9);
  border: 1px solid #3cd8e3;
}
.header-com.scrolled nav.navbar {
  background: transparent;
  box-shadow: none;
}

.header-com.scrolled {
  padding: 7px 72px;
  box-shadow: 0px 1px 16px 1px #ffffff;
  /* padding-left: 15px !important; */
  background-color: #ffffff29;
  backdrop-filter: blur(20px);
}
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: #42d4de;
  margin: 4px;
  transition: all 0.3s ease;
}

.hamburger.show div:nth-child(1) {
  transform: rotate(45deg) translate(10px, 10px);
}

.hamburger.show div:nth-child(2) {
  opacity: 0;
}

.hamburger.show div:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 500;
  display: none;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.overlay.show {
  display: block;
  opacity: 1;
}

@media (max-width: 768px) {
  .navbar ul {
    position: fixed;
    top: 60px;
    right: -100%;
    background-color: transparent;
    width: 100%;
    padding: 20px 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: right 1s ease 0.5s;
    z-index: 1000;
  }

  .navbar ul.show {
    right: 0;
  }

  .hamburger {
    display: flex;
    z-index: 1001;
  }
}

div#dropdown-content {
  position: absolute;
  background: #42566a;
  right: 3px;
  margin-top: 14px;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  height: 89vh;
  overflow-y: scroll;
  width: 147px !important;
  display: none;
}
div#dropdown-content .column {
  margin: 5px;
}
div#dropdown-content div {
  margin: 5px 9px;
  cursor: pointer;
}
div#selected-country {
  cursor: pointer;
}

.listimg {
  display: flex;
  margin-left: 37px;
  padding: 8px 4px;
  border: 1px solid #00000082;
  border-radius: 26px;
  padding-top: 8px;
  height: 39px;
  margin-top: -9px;
}
.listimg img {
  width: 21px;
  height: 20px;
}
.logoimgtex span {
  font-size: 13px;
  padding: 10px 20px;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-left: 63px;
  display: none;
  box-shadow: 0px 0px 7px 1px #ffffff;
  cursor: pointer;
  display: none;
  transition: opacity 0.3s ease;
}
.show span {
  box-shadow: 0px 0px 7px 1px #68f7ff;
  color: black;
}

.header-com.scrolled .logo-white {
  display: none;
}

.header-com.scrolled .logo-color {
  display: block;
}

.header-com.scrolled .logoimgtex span {
  display: block;
}

.header-com.scrolled .logoimgtex {
  display: block;
}

.header-com.scrolled .connect-btn {
  border: none;
}
.logoimgtex img {
  width: 18px;
  height: 18px !important;
  margin: -4px;
  margin-left: 4px;
}
li.livi {
  margin: 0;
  height: 22px;
  width: 1.5px;
  background: #000;
}

/* / / width / / */
div#dropdown-content::-webkit-scrollbar {
  width: 0px;
}
.cuntry {
  position: fixed;
  right: 0px;
}
.cuntry img {
  width: 13%;
  float: right;
  margin-right: 15px;
  margin-top: 2px;
}
/* / hdrdroapdwn. .. .  /
/ Styling for the dropdown container / */
.dropdowns {
  position: relative;
  display: inline-block;
  height: 48px;
}

/* / Styling for the dropdown content / */
.listimg li.dropdowns .dropdowns-contents {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 300px;
  z-index: 1;
  margin-left: 2px;
  margin-top: 54px;
  right: 1px;
  padding: 27px;
  border-top: 4px solid #70ccda;
  border-radius: 15px;
  box-shadow: 0px 0px 24px 7px #70ccda;
  left: -200px;
}
.dropdowns-contents h3 {
  font-weight: 500;
  color: #00000087;
  font-size: 16px;
  margin: 5px 0px;
}
.dropdowns-contents h2 {
  font-size: 16px;
  color: #3fcfda;
  font-weight: 500;
}
.dropdowns-contents h3 {
  font-weight: 500;
  color: #00000057;
  font-size: 16px;
  margin: 5px 0px;
}
.dropdowns-contents p {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  padding-top: 1px;
  line-height: 22px;
}

/* / Dropdown content items / */
.dropdowns-contents a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* / Change color on hover / */
.listimg li.dropdowns:hover .dropdowns-contents {
  background-color: #f1f1f1;
  visibility: visible;
  opacity: 1;
}

/* / Show the dropdown when hovering over the dropdown container / */
nav.navbar .dropdowns:hover .dropdowns-contents {
  display: block;
}

i.fas.fa-globe {
  font-size: 31px;
  margin-right: 25px;
  margin-top: 5px;
}
i.fas.fa-globe:hover {
  color: #4cc9d4;
}
.invosecah1 {
  font-size: 68px;
  font-weight: 700;
  color: black;
  line-height: 86px;
}
.invosecaimg {
  width: 17%;
  position: relative;
  margin-bottom: -11px;
  margin-left: 8px;
}
p.invosecap {
  font-size: 30px;
  line-height: 47px;
  color: #0eb7c5b5;
}

.dropdownn {
  display: none;
  color: white;
}

li.navbar-dropdownn:hover .dropdownn {
  display: block;
  color: white !important;
  display: inline-flex;
  flex-direction: column-reverse;
  height: 122px;
  border: 1px solid grey;
  padding-bottom: 31px;
  margin-top: 68px;
  position: absolute;
  right: 54px;
  background: whitesmoke;
}
/* .btsn.nvn {
  display: flex;
}
.btsn.nvn img {
  width: 10%;
} */

.mega-menu {
  position: absolute;
  background-color: #efefef;
  width: auto;
  top: 55px;
  left: 0;
  right: 1px;
  /* padding: 1.5rem; */
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  box-shadow: 0px 0px 24px 7px #70ccda;
  opacity: 1;
  visibility: visible;
  transform: translateY(20px);
  transition: all 0.3s ease;
  z-index: 999;
  backdrop-filter: blur(10px);
  border-top: 3px solid #70ccda;
  padding: 16px 35px;
}

.navbar-links li:hover .mega-menu {
  opacity: 1;
  visibility: visible;
}

.mega-menu ul {
  list-style: none;
  /* width: 91vw; */
}

div#service {
  margin-left: -485px;
  height: 70vh;
  width: 89vw;
}

@media (min-width: 1590px) and (max-width: 1800px) {
  div#service {
    margin-left: -569px;
    height: 70vh;
    width: 80vw;
  }
  .mega-menu .submenu {
    display: none;
    /* top: 14%; */
    left: 0px;
    background-color: #fff;
    padding: 10px 60px;
    text-align: left;
    margin-top: 20px;
    width: 80vw !important;
    height: 60.3vh;
    position: absolute;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #bbbbbb;
  }
}

.mega-menu li {
  margin-bottom: 10px;
}

.mega-menu li a {
  color: #333;
  font-weight: 500;
  text-decoration: none;
  /* padding: 10px 12px; */
  border-radius: 6px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mega-menu li a:hover {
  color: #000000 !important;
}

.header-com .navbar-links a:hover {
  color: #007bff;
}
.mega-menu > ul > li {
  /* position: relative; */
  display: inline-block;
  margin-right: 0px;
}

.mega-menu .submenu {
  display: none; /*chnage*/
  /* top: 14%; */
  left: 0px;
  background-color: #fff;
  padding: 10px 60px;
  text-align: left;
  margin-top: 20px;
  width: 89vw;
  height: 60.3vh;
  position: absolute;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #bbbbbb;
}
.submenu .col-md-6 {
  margin-top: 50px !important;
}
.myportfolioimg .col-md-6.myportcol {
  margin-top: 0 !important;
  padding: 40px;
}
#portfolio .col-md-6.myportcol img.myportcolimgtow {
  width: 250px !important;
  margin: 15px;
  height: 9vh !important;
}
#portfolio .col-md-6.myportcol img.myportcolimgone{
  width: 250px!important;
  margin: 15px; 
  height: 9vh!important;
}
ul.submenu.open {
  display: block;
  margin-top: 20px;
}

.mega-menu > ul > li:hover .submenu {
  display: block;
}
.mega-menu > ul > li :hover .open {
  display: none; /*chnage*/
}
.submenu img {
  width: 65%;
  float: inline-end;
  margin-right: 98px;
  /* border: 1px solid #bfbfbf; */
  border-radius: 10px;
}

.mega-menu .submenu li {
  padding: 0px 0;
  /* border-bottom: 1px solid #919191; */
}

.mega-menu .submenu li a {
  color: #333;
  text-decoration: none;
}

.mega-menu .submenu li a:hover {
  color: #007bff;
}
#submenu-h1 {
  font-size: 28px;
  color: #5891c0;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 0px;
  font-weight: 700;
  /* margin-top: -50px; */
  position: relative;
  top: -50px;
}
.blockchain-btns {
  display: flex;
  gap: 10px;
  margin: 10px;
  margin-bottom: 6px;
  position: relative;
  top: -50px;
}
.blockchain-btns button {
  margin-right: 4px;
  background: #5891c0;
  border: none;
  border-radius: 3px;
  padding: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}
.blockchain-btns :hover {
  opacity: 0.7;
}
#portfolio {
  left: 0px;
  /* display: flex; */
  background-color: #f9f9f9;
  padding: 10px 0px;
  text-align: center;
  margin-top: 20px;
  width: 42vw;
  height: 40vh !important;
  position: absolute;
}

#service .submenu li a:hover {
  color: #5891c0 !important;
}
/* #portfolio img {
  width: 100%;
  margin: 20px 0px;
  height: 50%;
  object-fit: contain;
} */
#portfolio img {
  width: 280px !important;
  margin: 0px -45px;
  height: 35vh !important;
  object-fit: contain;
}
#portfolio img:hover {
  filter: drop-shadow(3px 4px 5px #70ccda);
}
div#portfolio-mega-menu {
  height: 45vh;
  width: 42vw;
  margin-left: 70px;
}
#portfolio-mega-menu ul {
  height: 53vh;
}
div#marketing {
  width: 50%;
  position: absolute;
  left: 52px;
  padding: 13px;
  background-color: #fff;
}
div#marketing ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0px;
}
#marketing li a:hover {
  color: #5891c0 !important;
}
#nav-list a img {
  width: 24px;
  height: 24px;
  margin-right: 0px;
}
.bfhgd {
  height: 300px;
  border-left: 2px solid #7a7a7a;
  margin-top: 30px;
}
.logo-color {
  display: none;
}
/* .highlight {
  border-top: 3px solid #5580b7;
  border-bottom: 3px solid #5580b7;
  padding: 6px 0px;
} */
.newmega a:hover {
  position: relative;
}

.newmega a:hover:after {
  height: 2px;
  position: absolute;
  content: "";
  background-color: #5580b7;
  width: 0;
  margin-top: -5px;
  transition: width 0.3s ease;
  animation: slide-right 0.3s ease;
}
.newmega a {
  color: #000 !important;
  font-weight: 700 !important;
}
.newmega a:hover:before {
  height: 2px;
  position: absolute;
  content: "";
  background-color: #5580b7;
  width: 0;
  margin-top: 30px;
  transition: width 0.3s ease;
  animation: slide-right 0.3s ease;
  right: 0;
}

@keyframes slide-right {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes slide-left {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.newmega a:hover:after {
  width: 100%;
}

.newmega a:hover:before {
  width: 100%;
}

.token-development {
  margin-top: 10px;
}
.other-development {
  margin-top: 10px;
}
.active {
  transform: scale(0.9);
  font-weight: 500;
  transition: transform 0.3s ease;
  opacity: 0.8;
}
.active-link {
  color: #001936 !important;
}
.sep {
  width: 2px;
  background-color: black;
}
/* .logoimgtex.show {
  display: block;
  opacity: 1;
} */
.logo-img {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.newmega a.native-apps {
  position: relative;
}

.newmega a.native-apps.hover-effect:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  background-color: #5580b7; /* Example color */
  margin-top: -5px;
}
.newmega a.native-apps.hover-effect:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -6px;
  width: 100%;
  height: 2px;
  background-color: #5580b7; /* Example color */
  /* margin-top: 40px!important; */
}
#line-removal {
  text-decoration: none;
}
ul.submenu.subtow .col-md-6 {
  margin-top: 10px !important;
}
ul.submenu.subtow #submenu-h1 {
  top: 0px;
}
