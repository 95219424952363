.anv{
    position: relative;
    animation: anv;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-direction: reverse;
  }
  @keyframes anv{
    from {left: 0px;}
    to {left: 200px;}
  }
  .et_pb_section.et_pb_section_1_tb_header.background.fixed-top.pa-header.sticky-header.nmv.et_pb_with_background.et_section_regular.et_pb_section--fixed{
      left: 0%!important;
  }
    .bg {
    transition: height 0.3s ease; 
  }
      /* .vof {
      overflow: scroll;
          height:100%;
          padding-top: 10%;
  } */
  /* .bg-open {
      height: 105vh;
      background-color: #0d111a;
      padding: 22% 3%;
      margin-top: -20%;
      position: absolute;
      z-index: -1;
      width: 104%;
      margin-left: 0%;
  } */
    /* body.menu-open {
      overflow: hidden;
    } */
    /* .et_pb_section.et_pb_section_1_tb_header.background.fixed-top.pa-header.sticky-header.et_pb_with_background.et_section_regular.et_pb_section--fixed .et_pb_row {
      width: 100%!important;
  } */
    /* .et_pb_section.et_pb_section_1_tb_header.background.fixed-top.pa-header.sticky-header.et_pb_with_background.et_section_regular.et_pb_section--fixed {
      padding: 0;
      background-color: transparent!important;
  } */
    /* .et_pb_section_1_tb_header {
      transform: translateX(0%);
  } */
   .nevigation {
       outline: 2px solid aqua!important;
       border-radius: 15px;
      position: sticky!important;
      height: 58px;
      background: linear-gradient(45deg, #ffffff, #ffffff);
      margin-top: -10px;
      width: 97%;
      margin: auto;
  }
   .brand {
      position: absolute;
       padding-left: 20px;
       float: left;
       line-height: 55px;
       text-transform: uppercase;
       font-size: 1.4em;
  }
   /* .brand a, .brand a:visited {
       color: #ffffff;
       text-decoration: none;
  } */
   /* .nev-container {
       max-width: 1000px;
       margin: 0 auto;
       z-index: 11;
  } */

   /* .nev ul li {
       width: 100%;
       float: left;
       position: relative;
       list-style: none;
  } */
   /* .nev ul li a,.nev ul li a:visited {
    display: block;
      padding: 0 20px;
      line-height: 55px;
      color: #a9a7b0!important;
      background: #0d111a;
      text-decoration: none;
      font-size: 24px;
       width: 100%;
  } */
   /* .nev ul li a{
       background: transparent;
       color: #FFF;
  } */
   /* .nev ul li a:hover, .nev ul li a:visited:hover {
       color: #ffffff;
  } */
   /* .nevbar-dropdown li a{
       background: #0d111a;
  } */
   /* .nev ul li a:not(:only-child):after, .nev ul li a:visited:not(:only-child):after {
       padding-left: 4px;
       content: ' \025BE';
  } */
   /* .nev ul li ul li {
       min-width: 190px;
  }
   .nev ul li ul li a {
       padding: 15px;
       line-height: 20px;
  }
   .nevbar-dropdown {
       position: absolute;
       display: none;
       z-index: 1;
       box-shadow: 0 0 35px 0 rgba(0,0,0,0.25);
  } */
   .nev-mobile {
        margin: 2% 1%;
      border-radius: 50%;
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      background: #0d111a;
      height: 46px;
      width: 45px;
  }
   @media only screen and (max-width: 800px) {
       .nev-mobile {
           display: block;
      }
       .nev {
           width: 100%;
      }
       .nev ul {
           display: none;
      }
       .nev ul li {
           float: none;
      }
       .nev ul li a {
           padding: 15px;
           line-height: 20px;
      }
       .nev ul li ul li a {
           padding-left: 30px;
      }
       .nevbar-dropdown {
           position: static;
  }
   }
   @media screen and (min-width:800px) {
       .nev-list {
           display: block !important;
      }
  }
   #nevbar-toggle {
       background-color: black;
      position: absolute;
      left: 14px;
      top: 21px;
      cursor: pointer;
      padding: 0px 0px 0px 0px;
  }
   #nevbar-toggle span, #nevbar-toggle span:before, #nevbar-toggle span:after {
       cursor: pointer;
       border-radius: 1px;
       height: 3px;
       width: 18px;
       background: #ffffff;
       position: absolute;
       display: block;
       content: '';
       transition: all 300ms ease-in-out;
  }
   #nevbar-toggle span:before {
       top: -7px;
  }
   #nevbar-toggle span:after {
       bottom: -8px;
  }
   #nevbar-toggle.active span {
       background-color: transparent;
  }
   #nevbar-toggle.active span:before, #nevbar-toggle.active span:after {
       top: 0;
  }
   #nevbar-toggle.active span:before {
       transform: rotate(45deg);
  }
   #nevbar-toggle.active span:after {
       transform: rotate(-45deg);
  }
  /* .nev ul li a:not(:only-child):after, nev ul li a:visited:not(:only-child):after {
    padding-left: 4px;
    content: ' \2192';
  } */
  /* ul.nevbar-dropdown li a {
    font-size: 14px!important;
      padding: 4px 36px!important;
  } */
  /* ul.nevbar-dropdown h1 {
    font-size: 17px;
      padding: 12px;
      font-weight: 600;
      background: #0d111a;
      color: #a5a3ac;
      border-bottom: 1px solid #9999997a;
  } */
  .brand img {
    width: 24%;
      margin-top: 8px;
  }
  .nev {
      overflow: scroll!important;
  }
  /* .active:after {
      content: none!important;
  } */
  /* ul.iconsf {
    display: flex;
      margin-top: -1px;
      background: #0d111a;
      padding: 31px 18px;
  } */
  /* ul.iconsf li {
      min-width: 36px;
  }
  ul.iconsf li a {
      padding: 0px;
      padding-left: 0px;
  }
  ul.iconsf li {
      background: #0d111a;
  }
  .iconsf li a {
      background: #0d111a;
  }
  ul.nevbar-dropdown li a {
      color: #a9a7b0!important;
  } */
/* nevoverly to darken background */
.nevoverly {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgb(13 17 26);/* Black background with opacity */
    z-index: 999; /* Above everything except the menu */
  }
  
  /* Menu styling */
  .bg {
    display: none; /* Initially hidden */
    transition: all 0.3s ease-in-out;
    background-color: #0d111a;
    position: absolute;
    z-index: 1000; /* Above the nevoverly */
    width: 100%;
    margin-top: 28px;
  }
  ul.nev-list.anv li {
    list-style: none;
}
ul.nev-list.anv li a {
    color: #a9a7b0;
    text-decoration: none;
    font-size: 24px;
    font-weight: 600;
    line-height: 56px;
}
ul.nev-list.anv {
    padding-left: 11px;
}
  
  /* Additional styles for header/logo (optional) */
  .nev-container {
    position: relative;
    z-index: 1001; /* Ensure it appears above the nevoverly */
  }
  .tabimg1 img{
    margin-top: 19px;
    width: 100%;
  }
  .mobhdrc section {
    position: relative;
    top: 12px;
}
.dropmenu:after {
    padding-left: 4px;
    content: ' \2192';
}
ul.nevbar-dropdown {
    padding-left: 19px;
}
ul.nevbar-dropdown li a {
    font-size: 16px !important;
    line-height: 31px !important;
    font-weight: 500 !important;
}
ul.nevbar-dropdown h1 {
    color: #fff;
    font-size: 19px;
    font-weight: 600;
}
.mobhdrc{
    display: none;
  }
@media (min-width: 320px) and (max-width: 767px) {
.header-com{
    display: none;
    opacity: 0;
  }
  .mobhdrc{
    display: block!important;
    opacity: 1;
  }
}
.linediv {
    background: #fff;
    height: 1px;
    width: 100%;
}